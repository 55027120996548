import { useEffect } from "react";
import { SidepanelLogoElement } from "../SidepanelLogoElement/SidepanelLogoElement";

/** Handles the content */
export function Drawer({
  isOpen,
  handleClose,
  children,
  level = 1,
  stayOpen = false,
  hideLeftArrow = false,
  addLeftPaddingToTitle = false,
  hideDisclaimer = true,
}) {
  // Handle escape key functionality
  useEffect(() => {
    function handleEscapeKey(e) {
      if (!stayOpen && e.key === "Escape") handleClose()("Drawer");
    }

    document.addEventListener("keyup", handleEscapeKey);

    return () => {
      document.removeEventListener("keyup", handleEscapeKey);
    };
  }, [handleClose, stayOpen]);

  let drawerState;

  if (level === 1) {
    drawerState = isOpen
      ? " drawer-bottom-mobile-open "
      : " drawer-bottom-mobile-closed ";
  } else {
    drawerState = isOpen
      ? " drawer-left-mobile-open "
      : " drawer-left-mobile-closed ";
  }

  const marginTopState = level === 1 ? "mt-32" : "mt-32";
  const positionState = stayOpen ? " h-full " : " fixed ";
  const zLevel = ` z-${level + 1}0 `;

  return (
    <>
      <aside
        className={
          `w-full pt-4 lg:h-full lg:pt-0 lg:my-0 lg:max-w-md bg-gray-900 bg-opacity-5 inset-0 transform ease-in-out` +
          zLevel +
          marginTopState +
          positionState +
          drawerState
        }
      >
        <section
          className={
            "md:max-w-md w-full left-0 h-full absolute bg-black shadow-xl delay-400 duration-500 ease-in-out transition-all transform" +
            (isOpen
              ? " translate-y-0 translate-x-0 "
              : " translate-y-full lg:translate-y-0 lg:-translate-x-full ")
          }
        >
          <div
            className={`
              py-6 md:pt-8 md:pb-1 px-4 ${
                addLeftPaddingToTitle ? "pl-12 md:pl-12" : ""
              } md:px-8
             text-white shadow-2xl bg-gradient-to-b from-black to-gray-900/0
             `}
          >
            <SidepanelLogoElement
              handleClose={handleClose}
              hideLeftArrow={hideLeftArrow}
            />
          </div>
          <div className="mt-4 h-full overflow-y-auto hide-scrollbar pb-[12.5rem]">
            <>
              {children}
              {!hideDisclaimer && (
                <p className="text-gray-500 text-center italic mt-4 mb-4">
                  These hexes update every 30 minutes
                </p>
              )}
            </>
          </div>
        </section>
      </aside>
      <span className="hidden z-10 mt-40 drawer-left-mobile-open" />
      <span className="hidden z-20 mt-0 drawer-left-mobile-closed" />
      <span className="hidden z-30 drawer-bottom-mobile-open" />
      <span className="hidden z-40 drawer-bottom-mobile-closed" />
      <span className="hidden z-50 pl-12" />
    </>
  );
}
