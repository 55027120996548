import PropTypes from "prop-types";
import ChevronUp from "../icons/ChevronUp";
import { ButtonMapInterface } from "./ButtonMapInterface.tsx";

/** A button that retrieves the sidepanel when clicked */
function ButtonDisplaySidepanel({ handleClick }) {
  return (
    <ButtonMapInterface handleClick={handleClick}>
      <ChevronUp />
    </ButtonMapInterface>
  );
}

ButtonDisplaySidepanel.propTypes = {
  /** A function to handle the click event */
  handleClick: PropTypes.func.isRequired,
};

export default ButtonDisplaySidepanel;
