/**
 * Triggers a pause in the event loop for a given time
 *
 * @param {number} milliseconds the time to elapse, in milliseconds
 * @returns
 */
export const sleep = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export default sleep;
