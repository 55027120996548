function LocationTrigger() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "relative",
        right: "1px",
      }}
    >
      <path
        d="M1 13.6665L26.3333 1.6665L14.3333 26.9998L11.6667 16.3332L1 13.6665Z"
        fill="#7583FF"
        stroke="#7583FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LocationTrigger;
