import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setActiveResult } from "../../reducers/geocoder.ts";

export function GeocoderResult(props) {
  const { title, locationDescription, handleClick, last, index } = props;
  const { activeResult } = useSelector((state) => state.geocoder);
  const dispatch = useDispatch();

  return (
    <>
      <button
        onClick={handleClick}
        onMouseEnter={() => dispatch(setActiveResult(index))}
        className={
          "px-4 py-2 grid grid-cols-[1fr_40px] justify-start transition-all ease-in-out w-full lg:w-[30rem] " +
          `${last ? "" : "border-b border-gray-300"}` +
          `${activeResult === index ? " bg-amber-100" : ""}`
        }
      >
        <div className="flex flex-col items-start overflow-hidden">
          <div className="grid grid-cols-[1fr_65px] w-full gap-x-2 align-start">
            <h4 className="font-semibold text-start text-xl truncate text-ellipsis max-w-sm">
              {title}
            </h4>
            <div className="uppercase bg-yellow-really mt-1 rounded-full py-1 h-8 relative bottom-1 px-4 text-md">
              hex
            </div>
          </div>
          <div className="text-left">{locationDescription}</div>
        </div>
        <div className="justify-self-end self-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </button>
      <div className="hidden bg-amber-100" />
    </>
  );
}

GeocoderResult.propTypes = {
  /** Title of location */
  title: PropTypes.string.isRequired,
  /** Illstrates which area this result exists within*/
  locationDescription: PropTypes.string.isRequired,
  /** Controls the copy for number of hotspots available */
  numberHotspots: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** Action on click */
  handleClick: PropTypes.func.isRequired,
  /** When set to true, is the last in the series */
  last: PropTypes.bool.isRequired,
};
