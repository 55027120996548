import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setMobileStatus } from "../reducers/mobile.ts";

// md = 768px -> https://tailwindcss.com/docs/responsive-design
const mobileBreakpoint = 1024;

// Determines if the window is mobile or not. Sends result to redux.
export function useIsMobile() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      dispatch(setMobileStatus(window.innerWidth < mobileBreakpoint));
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
    // Empty array ensures that effect is only run on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
