import React from "react";

function ReallyWordmark() {
  return (
    <svg
      width="227"
      height="41"
      viewBox="0 0 227 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6354_5513)">
        <path
          d="M11.6526 8.80463C13.2136 8.80463 14.4849 10.076 14.4849 11.6369C14.4849 13.1979 13.2136 14.4693 11.6526 14.4693C10.0916 14.4693 8.82026 13.1979 8.82026 11.6369C8.82026 10.076 10.0916 8.80463 11.6526 8.80463ZM11.6526 8C9.64099 8 8.01562 9.62536 8.01562 11.6369C8.01562 13.6485 9.64099 15.2739 11.6526 15.2739C13.6642 15.2739 15.2895 13.6485 15.2895 11.6369C15.2895 9.62536 13.6642 8 11.6526 8Z"
          fill="white"
        />
        <path
          d="M20.5198 8.80463C22.0808 8.80463 23.3521 10.076 23.3521 11.6369C23.3521 13.1979 22.0808 14.4693 20.5198 14.4693C18.9588 14.4693 17.6874 13.1979 17.6874 11.6369C17.6874 10.076 18.9588 8.80463 20.5198 8.80463ZM20.5198 8C18.5082 8 16.8828 9.62536 16.8828 11.6369C16.8828 13.6485 18.5082 15.2739 20.5198 15.2739C22.5313 15.2739 24.1567 13.6485 24.1567 11.6369C24.1567 9.62536 22.5233 8 20.5198 8Z"
          fill="white"
        />
        <path
          d="M29.3796 8.80463C30.9406 8.80463 32.2119 10.076 32.2119 11.6369C32.2119 13.1979 30.9406 14.4693 29.3796 14.4693C27.8186 14.4693 26.5473 13.1979 26.5473 11.6369C26.5473 10.076 27.8186 8.80463 29.3796 8.80463ZM29.3796 8C27.368 8 25.7427 9.62536 25.7427 11.6369C25.7427 13.6485 27.368 15.2739 29.3796 15.2739C31.3912 15.2739 33.0166 13.6485 33.0166 11.6369C33.0166 9.62536 31.3912 8 29.3796 8Z"
          fill="white"
        />
        <path
          d="M11.6526 17.6718C13.2136 17.6718 14.4849 18.9431 14.4849 20.5041C14.4849 22.0651 13.2136 23.3365 11.6526 23.3365C10.0916 23.3365 8.82026 22.0651 8.82026 20.5041C8.82026 18.9431 10.0916 17.6718 11.6526 17.6718ZM11.6526 16.8672C9.64099 16.8672 8.01562 18.4925 8.01562 20.5041C8.01562 22.5157 9.64099 24.1411 11.6526 24.1411C13.6642 24.1411 15.2895 22.5157 15.2895 20.5041C15.2895 18.4925 13.6642 16.8672 11.6526 16.8672Z"
          fill="white"
        />
        <path
          d="M20.5198 17.6718C22.0808 17.6718 23.3521 18.9431 23.3521 20.5041C23.3521 22.0651 22.0808 23.3365 20.5198 23.3365C18.9588 23.3365 17.6874 22.0651 17.6874 20.5041C17.6874 18.9431 18.9588 17.6718 20.5198 17.6718ZM20.5198 16.8672C18.5082 16.8672 16.8828 18.4925 16.8828 20.5041C16.8828 22.5157 18.5082 24.1411 20.5198 24.1411C22.5313 24.1411 24.1567 22.5157 24.1567 20.5041C24.1567 18.4925 22.5313 16.8672 20.5198 16.8672Z"
          fill="white"
        />
        <path
          d="M29.3796 17.6718C30.9406 17.6718 32.2119 18.9431 32.2119 20.5041C32.2119 22.0651 30.9406 23.3365 29.3796 23.3365C27.8186 23.3365 26.5473 22.0651 26.5473 20.5041C26.5473 18.9431 27.8186 17.6718 29.3796 17.6718ZM29.3796 16.8672C27.368 16.8672 25.7427 18.4925 25.7427 20.5041C25.7427 22.5157 27.368 24.1411 29.3796 24.1411C31.3912 24.1411 33.0166 22.5157 33.0166 20.5041C33.0166 18.4925 31.3912 16.8672 29.3796 16.8672Z"
          fill="white"
        />
        <path
          d="M11.6526 26.5305C13.2136 26.5305 14.4849 27.8018 14.4849 29.3628C14.4849 30.9238 13.2136 32.1951 11.6526 32.1951C10.0916 32.1951 8.82026 30.9238 8.82026 29.3628C8.82026 27.8018 10.0916 26.5305 11.6526 26.5305ZM11.6526 25.7258C9.64099 25.7258 8.01562 27.3512 8.01562 29.3628C8.01562 31.3744 9.64099 32.9997 11.6526 32.9997C13.6642 32.9997 15.2895 31.3744 15.2895 29.3628C15.2895 27.3512 13.6642 25.7258 11.6526 25.7258Z"
          fill="white"
        />
        <path
          d="M20.5198 26.5305C22.0808 26.5305 23.3521 27.8018 23.3521 29.3628C23.3521 30.9238 22.0808 32.1951 20.5198 32.1951C18.9588 32.1951 17.6874 30.9238 17.6874 29.3628C17.6874 27.8018 18.9588 26.5305 20.5198 26.5305ZM20.5198 25.7258C18.5082 25.7258 16.8828 27.3512 16.8828 29.3628C16.8828 31.3744 18.5082 32.9997 20.5198 32.9997C22.5313 32.9997 24.1567 31.3744 24.1567 29.3628C24.1567 27.3512 22.5313 25.7258 20.5198 25.7258Z"
          fill="white"
        />
        <path
          d="M29.3796 26.5305C30.9406 26.5305 32.2119 27.8018 32.2119 29.3628C32.2119 30.9238 30.9406 32.1951 29.3796 32.1951C27.8186 32.1951 26.5473 30.9238 26.5473 29.3628C26.5473 27.8018 27.8186 26.5305 29.3796 26.5305ZM29.3796 25.7258C27.368 25.7258 25.7427 27.3512 25.7427 29.3628C25.7427 31.3744 27.368 32.9997 29.3796 32.9997C31.3912 32.9997 33.0166 31.3744 33.0166 29.3628C33.0166 27.3512 31.3912 25.7258 29.3796 25.7258Z"
          fill="white"
        />
      </g>
      <path
        d="M114.297 8.12476L102.427 32.8777H114.917L116.213 29.4104H126.897L128.193 32.8777H140.683L128.815 8.12476H114.297ZM118.56 23.1314L121.555 15.1217L124.548 23.1314H118.558H118.56Z"
        fill="white"
      />
      <path
        d="M206.539 8.12476L201.438 16.6163L196.338 8.12476H183.614L195.86 24.1745H195.875V32.8777H207.002V24.1745H207.018L219.266 8.12476H206.539Z"
        fill="white"
      />
      <path
        d="M84.2747 8.12476H76.4482V32.8777H84.2747H87.5757H101.26V26.5987H87.5757V23.4243H101.26V17.1454H87.5757V14.4037H101.26V8.12476H87.5757H84.2747Z"
        fill="white"
      />
      <path
        d="M152.982 8.12476H141.856V32.8777H149.682H152.982H166.666V26.5987H152.982V8.12476Z"
        fill="white"
      />
      <path
        d="M179.482 8.12476H168.355V32.8777H179.482V32.8644H193.165V26.5987H179.482V8.12476Z"
        fill="white"
      />
      <path
        d="M72.8229 20.1152V13.5264C72.8229 12.8178 72.6831 12.1149 72.411 11.4592C72.1389 10.8036 71.7383 10.2084 71.2357 9.70574C70.7312 9.20313 70.1341 8.80633 69.4746 8.53423C68.8171 8.26214 68.1104 8.12231 67.398 8.12231H40.5156V32.8771H51.6431V26.5982H57.8464L62.5193 32.8771H75.4852L68.2427 26.0918C70.5271 25.1678 72.8229 23.3123 72.8229 20.1152ZM61.9713 18.7264C61.9713 19.1496 61.8031 19.5559 61.5008 19.8563C61.2004 20.1549 60.7922 20.3249 60.3671 20.3249H51.6431V14.4126H57.6027V14.4031H60.3652C60.7903 14.4031 61.1985 14.5713 61.4989 14.8717C61.7993 15.1703 61.9694 15.5765 61.9694 16.0017V18.7302L61.9713 18.7264Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_6354_5513">
          <rect
            x="0.515625"
            y="0.5"
            width="40"
            height="40"
            rx="10"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReallyWordmark;
