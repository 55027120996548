import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MagnifyingGlass } from "../../icons/MagnifyingGlass";
import { GeocoderResult } from "./GeocoderResult";
import { geocoderZoomLevels } from "../../utils/geocoderZoomLevels";

import {
  fetchGeocoderContent,
  setActiveResult,
} from "../../reducers/geocoder.ts";

const zoomLevels = geocoderZoomLevels();

/** Handles Geocoder searching. Assumptions:
 * 1. Mapbox will not fail for its geocoder. If the servers are down, the entire site fails (because it's a mapbox map)
 * 2. Mapbox will not reject the request. If the request rejects due to money issues, it'll affect the map as well and the entire site will.. fail.
 * 3. If the local API fails, then the node count will just hide.
 */
export function GeocoderInput({
  handleLocationSelect,
  mapCenter = "",
  clearPin,
}) {
  const { status, error, results, keyContent, activeResult } = useSelector(
    (state) => state.geocoder
  );
  const loading = status === "loading";

  const dispatch = useDispatch();

  const containerElementRef = useRef(null);

  function handleInputChange(event) {
    dispatch(
      fetchGeocoderContent({ mapCenter, inputValue: event.target.value })
    );
  }

  function handleControlKeypress(event) {
    if (event.key === "ArrowUp" && activeResult > 0) {
      dispatch(setActiveResult(activeResult - 1));
    } else if (event.key === "ArrowDown" && activeResult < results.length - 1) {
      dispatch(setActiveResult(activeResult + 1));
    } else if (event.key === "Enter") {
      const {
        center,
        place_type: [placeType],
      } = results[activeResult];

      handleLocationSelect({ center, zoom: zoomLevels[placeType] ?? 10 });
      dispatch(fetchGeocoderContent({ mapCenter, inputValue: "" }));
    }
  }

  // Close all geocoder options on click outside
  useEffect(() => {
    function handleClickOutsideEvent(event) {
      if (
        containerElementRef.current &&
        !containerElementRef.current.contains(event.target)
      ) {
        dispatch(fetchGeocoderContent({ mapCenter, inputValue: "" }));
      }
    }

    document.addEventListener("mousedown", handleClickOutsideEvent);
    // Removes event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEvent);
    };
  });

  // HOF. Closes geocoder and moves the map
  const handleElementClick =
    ({ center, zoom }) =>
    () => {
      dispatch(fetchGeocoderContent({ mapCenter, inputValue: "" }));
      handleLocationSelect({ center, zoom });
    };

  return (
    <section
      className="block md:flex flex-col items-end mx-3 md:mx-8 lg:mx-0"
      ref={containerElementRef}
    >
      <div
        className="
                rounded-full overflow-hidden 
                border-gray-400 
                items-center bg-white 
                grid grid-cols-[24px_1fr_45px] px-8
                min-w-lg
                "
      >
        <MagnifyingGlass />
        <input
          value={keyContent}
          onChange={handleInputChange}
          onKeyUp={handleControlKeypress}
          onFocus={clearPin}
          placeholder="Search..."
          disabled={!!error}
          className="border-none ml-2 my-2 py-2 pr-4 px-1 focus:ring-transparent focus-visible:ring-transparent focus-visible:outline-none"
        />
        <div>
          {loading && (
            <div className="flex justify-center items-center ml-4">
              <div className="geocoder-loading" />
            </div>
          )}
        </div>
      </div>
      {results.length > 0 && (
        <div className="border border-gray-700 rounded mt-4 bg-white">
          {results.map((geocoderOption, index) => {
            const {
              center,
              text: name,
              place_name: fullName,
              place_type: [placeType],
              numberHotspots = -1,
            } = geocoderOption;

            return (
              <GeocoderResult
                key={`${name}-${index}`}
                title={name}
                index={index}
                locationDescription={fullName}
                numberHotspots={numberHotspots}
                handleClick={handleElementClick({
                  center,
                  zoom: zoomLevels[placeType] ?? 10,
                })}
                last={index === results.length + 1}
              />
            );
          })}
        </div>
      )}
    </section>
  );
}
