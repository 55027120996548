import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useMap, Source, Layer } from "react-map-gl";
import { pipe } from "ramda";
import bbox from "@turf/bbox";
import { track } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";

import { clearGeocoderResult } from "../../reducers/map.ts";
import sleep from "../../utils/sleep";
import {
  polygonFillLayer,
  polygonActiveFillLayer,
  polygonActiveStrokeLayer,
  hexagonTransitionFillLayer,
} from "./hexagonStyleDefinitions";

import { useLoadFlatgeobuf } from "./useLoadGeobuf";

const minZoomTransition = 9.5;

/**
 * Renders the hexagon polygons on the map from flatgeobuf, but not the labels of said hexagons
 */
function HexagonPolygonsLayers({ fileLocation }) {
  const { map } = useMap();
  const { search } = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const activeHexagonId = useSelector((state) => state.hexagon.hexagon.id);
  const { dewiCoverageVisible: hexagonsVisible } = useSelector(
    (state) => state.map
  );

  const [activeCellId, setActiveCellId] = useState(null);

  // Clear all polygon features
  const clearFeatureState = useCallback(() => {
    if (!map) return;

    if (map.getSource("hexagon-polygons")) {
      map.removeFeatureState({ source: "hexagon-polygons" });
    }

    if (map.getSource("hexagon-centroids")) {
      map.removeFeatureState({ source: "hexagon-centroids" });
    }
  }, [map]);

  const setFeatureState = useCallback((id) => {
    if (!map) return;

    map.removeFeatureState({ source: "hexagon-polygons" });
    map.removeFeatureState({ source: "hexagon-centroids" });

    map.setFeatureState({ source: "hexagon-polygons", id }, { active: true });
    map.setFeatureState({ source: "hexagon-centroids", id }, { active: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data } = useLoadFlatgeobuf({
    url: fileLocation,
    minzoom: minZoomTransition,
    activeCellId,
    setFeatureState,
  });

  useEffect(() => {
    if (activeCellId) {
      setFeatureState(activeCellId);
    } else {
      sleep(500).then(clearFeatureState);
    }
  }, [map, activeCellId, setFeatureState, clearFeatureState]);

  // Handle click effects
  useEffect(() => {
    if (!map) return;

    const handleClick = (e) => {
      const [feature] = e.features;

      dispatch(clearGeocoderResult());

      if (!feature.id) return;

      setActiveCellId(feature.id);

      let location = e.features[0].properties.location;
      if (typeof location === "string") location = JSON.parse(location);

      const { geometry } = feature;

      // Extra step ensures new hexagon gets loaded
      history("/");
      history(`/hexagon/${feature.properties.hexid}${search}`);

      // Expands the box out and just a little bit to the right to accomidate the sidepanel
      const expandBox = ([xMin, yMin, xMax, yMax]) => {
        return [xMin - 0.02, yMin - 0.0025, xMax + 0.01, yMax + 0.0025];
      };
      track({
        category: "hexagon",
        action: "Click",
        label: `hexagon ${feature.properties.hexid}`,
      });

      // Pans the map to the bbox location by generating the bbox, expanding it by a bit, then fitting the map to the new bounds
      pipe(bbox, expandBox, map.fitBounds)(geometry);
    };

    // Add event listeners

    [
      "hexagon-polygons-transition-fill",
      "hexagon-polygons-fill-filtered",
      "hexagon-polygons-fill",
    ].forEach((layer) => {
      map.on("click", layer, handleClick);
    });

    return () => {
      [
        "hexagon-polygons-transition-fill",
        "hexagon-polygons-fill-filtered",
        "hexagon-polygons-fill",
      ].forEach((layer) => {
        map.off("click", layer, handleClick);
      });
    };
  }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

  // Clear active state when sidepanel is minimized
  useEffect(() => {
    if (!Boolean(activeHexagonId) && map?.isStyleLoaded()) {
      clearFeatureState();
    }
  }, [activeHexagonId, map, clearFeatureState]);

  const extraProps = {};

  if (map.getZoom < 14.5) {
    extraProps.filter = ["==", ["get", "radioCount"], 0, false, true];
  }

  const transitionFilter = useMemo(
    () => ["==", ["get", "transitionVisible"], "true"],
    []
  );

  return (
    <Source type="geojson" id="hexagon-polygons" data={data}>
      <Layer
        id="hexagon-polygons-transition-fill"
        minzoom={minZoomTransition}
        maxzoom={11}
        {...hexagonTransitionFillLayer}
        beforeId="hexagon-title"
        {...extraProps}
        filter={transitionFilter}
      />

      {/* Same as set of four below, except has filter */}
      <Layer
        minzoom={11}
        maxzoom={13.5}
        id="hexagon-polygons-fill-filtered"
        {...polygonFillLayer}
        beforeId="hexagon-title"
        {...extraProps}
        filter={transitionFilter}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />
      <Layer
        minzoom={11}
        maxzoom={13.5}
        id="hexagon-polygons-active-fill-filtered"
        {...polygonActiveFillLayer}
        beforeId="hexagon-title"
        {...extraProps}
        filter={transitionFilter}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />
      <Layer
        minzoom={11}
        maxzoom={13.5}
        id="hexagon-polygons-active-stroke-filtered"
        {...polygonActiveStrokeLayer}
        {...extraProps}
        filter={transitionFilter}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />

      {/* Same as set of four above, except has no filter */}
      <Layer
        minzoom={13.5}
        id="hexagon-polygons-fill"
        {...polygonFillLayer}
        beforeId="hexagon-title"
        {...extraProps}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />
      <Layer
        minzoom={13.5}
        id="hexagon-polygons-active-fill"
        {...polygonActiveFillLayer}
        beforeId="hexagon-title"
        {...extraProps}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />
      <Layer
        minzoom={13.5}
        id="hexagon-polygons-active-stroke"
        {...polygonActiveStrokeLayer}
        {...extraProps}
        layout={{ visibility: hexagonsVisible ? "visible" : "none" }}
      />

      {process.env.NODE_ENV === "development" && (
        <aside className="bg-white p-4 z-50 fixed bottom-10 right-10">
          (dev only) Zoom: {map?.getZoom()}
        </aside>
      )}
    </Source>
  );
}

HexagonPolygonsLayers.propTypes = {
  /** The public URL that contains the flatgeobuf in question */
  fileLocation: PropTypes.string.isRequired,
};

export default HexagonPolygonsLayers;
