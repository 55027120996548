import ReactGA from "react-ga4";

const googleTrackcode = process.env.REACT_APP_GA_TRACKCODE;

let analyticsPackage;

class MockAnalytics {
  track(data) {
    const { category, action, label } = data;
    console.log("Track sent to Google Analytics", {
      category,
      action,
      label,
    });
  }
  page(data) {
    console.log("Track sent to Google Analytics", data);
  }
  send(data) {
    console.log("Page event sent to Google Analytics", data);
  }
  event(data) {
    console.log("Event sent to Google Analytics", data);
  }
}

if (googleTrackcode) {
  ReactGA.initialize(googleTrackcode);

  analyticsPackage = ReactGA;
} else {
  analyticsPackage = new MockAnalytics();
}

export function analyticsPageview({ path }) {
  if (process.env.NODE_ENV === "development") {
    console.log("Pageview sent to Google Analytics", path);
  }

  analyticsPackage.send({ hitType: "pageview", page: path });
}

export function track({ category, action, label }) {
  if (process.env.NODE_ENV === "development") {
    console.log("Track sent to Google Analytics", {
      category,
      action,
      label,
    });
  }
  analyticsPackage.event({ category, action, label });
}
