/**
 * Uses the mapbox.com geolocation API to populate the geocoder
 *
 * @param {string} queryString the search query
 * @param {string} options the list of options. Querystring format.
 *
 */
export async function fetchGeocodingResults(
  queryString: string,
  options: string
) {
  const throwError =
    process.env.REACT_APP_API_ERROR_MAPBOX_GEOCODER_SERVER === "true";

  if (throwError) options += "&throw_error=true";
  const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${queryString}.json?${options}`;

  const response = await fetch(apiUrl);

  if (response.status !== 200) {
    console.error("Error fetching geocoding results", response);

    throw new Error("Error fetching geocoding results");
  }
  const data = await response.json();
  return data.features;
}
