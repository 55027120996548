import { useEffect, useRef } from "react";
import { useMap } from "react-map-gl";

/** A component that when mounted, allows the console window to use the map object. */
function MapDebugger() {
  const map = useMap();

  const loaded = useRef(false);

  useEffect(() => {
    if (map && !loaded.current && process.env.NODE_ENV === "development") {
      console.log(
        "%cMapbox GL JS Debugger Mode active in local development -> window.map",
        "color: blue;"
      );

      loaded.current = true;
      window.map = map.current.getMap();
    }
  }, [map]);

  return null;
}

export default MapDebugger;
