/* 
    Used in conjunction with the Mapbox Geocoder API. 

    The following consist of the place_type attribute of a Mapbox 
    Geocoder result. 

    This object associates those places with a zoom level, 
    which is how deep the map will zoom as a user selects that option
*/

export const geocoderZoomLevels = () => {
  return {
    country: 8,
    region: 11,
    postcode: 13,
    district: 12,
    place: 10,
    locality: 11,
    neighborhood: 12,
    address: 15,
    poi: 15,
  };
};
