import { useSelector, useDispatch } from "react-redux";

import { toggleInitialPanel } from "../../reducers/sidepanel.ts";

import { Drawer } from "../Drawer/Drawer";

import ActionJoinNetwork from "../ActionJoinNetwork/ActionJoinNetwork";
import ReallySquishyGlobe from "../../icons/ReallySquishyGlobe.jsx";

export function SidepanelIntro() {
  const { mobile } = useSelector((state) => state.mobile);
  const initialPanelOpen = useSelector((state) => state.sidepanel.initialPanel);
  const dispatch = useDispatch();

  const handleClose = () => () => {
    dispatch(toggleInitialPanel());
  };

  return (
    <Drawer
      isOpen={initialPanelOpen}
      handleClose={handleClose}
      level={1}
      addLeftPaddingToTitle
      stayOpen={!mobile}
      hideLeftArrow
    >
      <div className="py-3 px-12 text-white">
        <article className="relative max-w-md flex flex-col overflow-y-auto hide-scrollbar h-full">
          <ReallySquishyGlobe />
          <h2 className="my-2 text-[44px] leading-none font-extralight">
            welcome to the explorer
          </h2>
          <p className="py-1 font-aeonik text-[18px] font-light">
            discover the REALLY DeWi network with our Explorer tool – a
            comprehensive availability map. Spots are limited to protect the
            quality of the network. click or tap a hex to reserve a spot.
          </p>
        </article>

        <div className="w-full grid grid-cols-1 gap-y-5 align-center items-center justify-center my-8">
          <ActionJoinNetwork />
        </div>
      </div>
    </Drawer>
  );
}
