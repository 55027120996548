import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/rangeSlider.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const sentryIoTrackcode = process.env.REACT_APP_SENTRY_TRACKCODE;

window.version = "v0.8.3";

// Used for mock-service-worker (msw)
if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_ENABLE_MSW === "true"
) {
  const { worker } = require("./mocks/browser");
  worker.start({ onUnhandledRequest: "bypass" });
}

if (sentryIoTrackcode && process.env.NODE_ENV === "production") {
  console.info("Sentry.io tracking enabled");
  Sentry.init({
    dsn: sentryIoTrackcode,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
