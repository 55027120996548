import { useEffect } from "react";

import { Drawer } from "../Drawer/Drawer";

import HexagonSmall from "../../icons/HexagonSmall";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchHexagon, resetHexagon } from "../../reducers/hexagon.ts";
import { useParams } from "react-router-dom";

import LoadingSpinner from "../LoadingSpinner";
import { MessageHexAvailability } from "./MessageHexAvailability";

import RadioListElement from "./RadioListElement";

/** Sidepanel content which appears when a hexagon is open */
export function SidepanelHexagonCell() {
  const { hexagonId } = useParams();

  const dispatch = useDispatch();
  const { hexagon, status, error } = useSelector((state) => state.hexagon);
  const history = useNavigate();
  const { status: hexagonStatus } = useSelector((state) => state.hexagon);

  const loading = status === "loading" || status === "idle";
  const activeHexagonId = hexagon?.id;
  const { city, radioSpacesPossible } = hexagon;

  useEffect(() => {
    if (hexagonId) {
      dispatch(fetchHexagon(hexagonId));
    } else if (hexagonStatus === "succeeded") {
      dispatch(resetHexagon());
    }
  }, [hexagonId, dispatch]); // eslint-disable-line

  const handleClose = () => () => {
    dispatch(resetHexagon());
    history("/");
  };

  const radioSlots = [];

  const numberRadios = hexagon?.nodes.length ?? 0;
  const numberRadiosPossible =
    hexagon?.nodes.length > radioSpacesPossible
      ? hexagon?.nodes.length
      : radioSpacesPossible;

  const radioSpacesAvailable = numberRadiosPossible - numberRadios;

  for (let i = 0; i < numberRadiosPossible; i++) {
    if (i < numberRadios) {
      radioSlots.unshift(true);
    } else {
      radioSlots.unshift(false);
    }
  }

  return (
    <>
      <Drawer isOpen={!!activeHexagonId} handleClose={handleClose}>
        <div className="text-white w-full md:w-[28rem]">
          {activeHexagonId && (
            <>
              {!error && (
                <section className="px-4 md:px-6 mb-6 ml-2">
                  <h2 className="text-5xl font-black col-span-2 mb-2">
                    hex details
                  </h2>
                  <h3 className="font-bold text-2xl">{city}</h3>

                  <p className="font-semibold flex flex-row items-center">
                    <span className="mt-[3px] mr-2">
                      <HexagonSmall />
                    </span>{" "}
                    {activeHexagonId}
                  </p>
                </section>
              )}

              {loading && (
                <>
                  <div className="md:w-[27.4rem] h-[24vh] flex justify-center items-center">
                    <LoadingSpinner />
                  </div>
                </>
              )}
              {error && (
                <div className="w-full flex pt-12 items-start">
                  <p className="text-red-500 p-2 mx-auto">
                    {error === "response status 404" ? (
                      <>404: Unknown hexagon ID.</>
                    ) : (
                      <>
                        Error loading node in region.{" "}
                        <button
                          className="underline hover:text-white ease-in-out duration-300"
                          onClick={() =>
                            dispatch(fetchHexagon(activeHexagonId))
                          }
                        >
                          try again?
                        </button>
                      </>
                    )}
                  </p>
                </div>
              )}
              {!(loading || error) && (
                <>
                  <section className="px-4 md:px-8">
                    <MessageHexAvailability
                      numberSpotsAvailable={radioSpacesAvailable}
                    />
                  </section>
                  <p className="my-2 px-4 md:px-12 mt-4">
                    REALLY radios in this hex ({hexagon?.nodes.length ?? 0} out
                    of {numberRadiosPossible})
                  </p>
                  <section className="overflow-y-auto hide-scrollbar w-full flex flex-col items-center px-4 md:px-8">
                    <div className="w-full">
                      {radioSlots.map((reserved, index) => (
                        <RadioListElement
                          reserved={reserved}
                          key={`radio-slot-${index}`}
                        />
                      ))}
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
