import { useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector, useDispatch } from "react-redux";

import HexagonLabelsLayer from "./HexagonLabelsLayer";
import MapDebugger from "../MapDebugger";
import { useHeatmapStyleDefinition } from "./useHeatmapStyleDefinition.js";
import HexagonPolygonsLayers from "./HexagonPolygonsLayers";
import MarkerGeocoderResult from "./MarkerGeocoderResult";
import ButtonGetLocation from "../ButtonGetLocation";
import ButtonDisplaySidepanel from "../ButtonDisplaySidepanel";
import { useInitialLoad } from "./useInitialLoad";
import { toggleInitialPanel } from "../../reducers/sidepanel.ts";

import useRetrieveLocation from "./useRetrieveLocation";
import ControlsMapLayerMobile from "../ControlsMapLayerMobile.jsx";
const centroidLocation = process.env.REACT_APP_CENTROID_FGB_LOCATION;
const polygonLocation = process.env.REACT_APP_POLYGON_FGB_LOCATION;
const heatmapTilesetId = process.env.REACT_APP_MAPBOX_HEATMAP_TILESET_ID;

const changeLocationsEnabled =
  process.env.REACT_APP_ENABLE_FILE_TROUBLESHOOTING === "true";

if (!(centroidLocation && polygonLocation)) {
  throw new Error(
    "Configuration error: Environment variables REACT_APP_CENTROID_FGB_LOCATION REACT_APP_POLYGON_FGB_LOCATION are not set"
  );
}

const tileBaseUrl = process.env.REACT_APP_TMOBILE_COVERAGE_TILES;

export function MapboxMap({ setSelectedHexagon, selectedHexagon }) {
  const [fileLocations, setFileLocations] = useState({
    hexagons: polygonLocation,
    centroids: centroidLocation,
  });

  const { heatmapLayerStyle } = useHeatmapStyleDefinition();

  window.displayFileLocations = function () {
    if (changeLocationsEnabled) {
      alert(
        `Centroid: ${fileLocations.centroids}\nHexagon: ${fileLocations.hexagons}`
      );
    }
  };

  const resetFileLocations = () =>
    setFileLocations({
      hexagons: polygonLocation,
      centroids: centroidLocation,
    });

  useEffect(() => {
    // detect a secret sequence of keypresses to enable file troubleshooting
    const secretMenuSequence = ["f", "i", "l", "e"];

    let sequence = [];
    const handleKeyDown = (e) => {
      sequence.push(e.key);
      if (sequence.length > secretMenuSequence.length) {
        sequence.shift();
      }
      if (sequence.join("") === secretMenuSequence.join("")) {
        let centroidFileLoc = prompt("enter centroid fgb location");

        if (!centroidFileLoc) {
          alert("using previous centroid location");
          centroidFileLoc = fileLocations.centroids;
        }

        let hexagonFileLoc = prompt("enter hexagon fgb location");

        if (!hexagonFileLoc) {
          alert("using previous hexagon location");
          hexagonFileLoc = fileLocations.hexagons;
        }

        const status = window.confirm(
          `Are you sure you want to change the file locations: \n\nCentroid: ${centroidFileLoc}\nHexagon: ${hexagonFileLoc}`
        );
        if (status) {
          alert("Centroid and Hexagon file locations changed");
          setFileLocations({
            hexagons: hexagonFileLoc,
            centroids: centroidFileLoc,
          });
        } else {
          alert("cancelled...");
        }
        sequence = [];
      }
    };

    if (changeLocationsEnabled) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // detect a secret sequence of keypresses to enable file troubleshooting
    const secretMenuSequence = ["r", "e", "s", "e", "t"];

    let sequence = [];
    const handleKeyDown = (e) => {
      sequence.push(e.key);
      if (sequence.length > secretMenuSequence.length) {
        sequence.shift();
      }
      if (sequence.join("") === secretMenuSequence.join("")) {
        resetFileLocations();
        alert("file locations reset");
        sequence = [];
      }
    };

    if (changeLocationsEnabled) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  const mapStyle = `mapbox://styles/really5g/clrqn7olk00a501o82w67f45t`;
  const { retrieveLocation } = useRetrieveLocation();
  const { mobile } = useSelector((state) => state.mobile);
  const { enhancedCoverageVisible: rasterVisibility } = useSelector(
    (state) => state.map
  );
  const dispatch = useDispatch();
  useInitialLoad();

  const handleOpenInitialSidepanel = () => {
    dispatch(toggleInitialPanel());
  };

  let urlParams = new URLSearchParams(window.location.search);

  let { lat, lng, zoom } = Object.fromEntries(urlParams.entries());

  return (
    <Map
      mapLib={mapboxgl}
      initialViewState={{
        longitude: lng ?? -97.738,
        latitude: lat ?? 30.261,
        zoom: zoom ?? 12.2,
      }}
      id="map"
      style={{ width: "100%", height: "100%" }}
      mapStyle={mapStyle}
      className="w-full h-full"
    >
      <HexagonLabelsLayer fileLocation={fileLocations.centroids} />
      <HexagonPolygonsLayers
        setSelectedHexagon={setSelectedHexagon}
        selectedHexagon={selectedHexagon}
        fileLocation={fileLocations.hexagons}
      />
      <Source
        type="vector"
        id="5g-coverage-points"
        url={`mapbox://${heatmapTilesetId}`}
        maxzoom={10}
      >
        <Layer {...heatmapLayerStyle} />
      </Source>
      <Source
        id="tmobile-raster"
        type="raster"
        tiles={[tileBaseUrl]}
        tileSize={256}
        // the following args represent the bounds of where raster tiles can be requested on the map
        // https://docs.mapbox.com/style-spec/reference/sources/#raster
        maxzoom={14}
        bounds={[-171.791110603, 18.91619, -66.96466, 71.3577635769]}
      >
        <Layer
          id="tmobile-raster-layer"
          type="raster"
          source="tmobile-raster"
          paint={{
            "raster-opacity": 0.5,
            "raster-fade-duration": 0,
          }}
          beforeId="building"
          layout={{ visibility: rasterVisibility ? "visible" : "none" }}
        />
      </Source>

      <MarkerGeocoderResult />
      {/* Allows access of map through chrome window */}
      <MapDebugger />

      <aside className="fixed bottom-5 right-0 z-10 flex items-center flex-col justify-evenly p-2">
        {mobile && (
          <>
            <ControlsMapLayerMobile />
            <ButtonDisplaySidepanel handleClick={handleOpenInitialSidepanel} />
          </>
        )}
        <ButtonGetLocation handleClick={retrieveLocation} />
      </aside>
    </Map>
  );
}
