import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const blankGeojson = {
  type: "FeatureCollection",
  features: [],
};

type geobufLayer = {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
  data: any;
};

export interface MapState {
  geocoderActive: boolean;
  geocoderCoordinates: [number, number];
  hexagonLabels: geobufLayer;
  hexagonPolygons: geobufLayer;
  dewiCoverageVisible: boolean;
  enhancedCoverageVisible: boolean;
}

const initialState: MapState = {
  hexagonLabels: {
    status: "idle",
    error: "",
    data: blankGeojson,
  },
  hexagonPolygons: {
    status: "idle",
    error: "",
    data: blankGeojson,
  },
  geocoderActive: false,
  geocoderCoordinates: [0, 0],
  dewiCoverageVisible: true,
  enhancedCoverageVisible: true,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setGeocoderResult: (state, action: PayloadAction<[number, number]>) => {
      state.geocoderActive = true;
      state.geocoderCoordinates = action.payload;
    },
    clearGeocoderResult: (state) => {
      state.geocoderActive = false;
      state.geocoderCoordinates = [0, 0];
    },
    toggleDewiCoverage: (state) => {
      state.dewiCoverageVisible = !state.dewiCoverageVisible;
    },
    toggleEnhancedCoverage: (state) => {
      state.enhancedCoverageVisible = !state.enhancedCoverageVisible;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGeocoderResult,
  clearGeocoderResult,
  toggleDewiCoverage,
  toggleEnhancedCoverage,
} = mapSlice.actions;

export default mapSlice.reducer;
