import { createSlice } from "@reduxjs/toolkit";

export interface SidepanelState {
  initialPanel: boolean;
  hexagonPanel: boolean;
  mobileLayerSelector: boolean;
}

const initialState: SidepanelState = {
  initialPanel: true,
  hexagonPanel: false,
  mobileLayerSelector: false,
};

export const sidepanelSlide = createSlice({
  name: "sidepanel",
  initialState,
  reducers: {
    internalToggleInitialPanel: (state) => {
      state.initialPanel = !state.initialPanel;
    },
    internalCloseMobileLayerSelectPanel: (state) => {
      state.mobileLayerSelector = false;
    },
    internalOpenMobileLayerSelectPanel: (state) => {
      state.mobileLayerSelector = true;
    },
  },
});

const {
  internalToggleInitialPanel,
  internalCloseMobileLayerSelectPanel,
  internalOpenMobileLayerSelectPanel,
} = sidepanelSlide.actions;

export function toggleInitialPanel() {
  return (dispatch: any, getState: any) => {
    const mobile = getState().mobile.mobile;

    if (!mobile) return;

    dispatch(internalToggleInitialPanel());
  };
}

export function openMobileLayerSelectPanel() {
  return (dispatch: any, getState: any) => {
    const mobile = getState().mobile.mobile;

    if (!mobile) return;

    dispatch(internalOpenMobileLayerSelectPanel());
  };
}

export function closeMobileLayerSelectPanel() {
  return (dispatch: any, getState: any) => {
    const mobile = getState().mobile.mobile;

    if (!mobile) return;

    dispatch(internalCloseMobileLayerSelectPanel());
  };
}

export default sidepanelSlide.reducer;
