import React from "react";

type Props = {
  handleClick: () => void;
  children: React.ReactNode;
};

/**
 * A simple button component used in the mobile map interface.
 * @param props.chilren - Generally the SVG icon to be displayed.
 * @param props.handleClick - The function to be called when the button is clicked.
 *
 * @example
 * <ButtonMapInterface handleClick={handleClick}>
 *   <svg
 *      width="26"
 *      height="18"
 *      viewBox="0 0 26 18"
 *      fill="none"
 *      xmlns="http://www.w3.org/2000/svg"
 *   >
 *     <path
 *       d="M1 9H25M1 1H25M1 17H25"
 *       stroke="black"
 *       strokeWidth="2"
 *       strokeLinecap="round"
 *       strokeLinejoin="round"
 *     />
 *   </svg>
 * </ButtonMapInterface>
 */
export function ButtonMapInterface(props: Props) {
  const { handleClick, children } = props;

  return (
    <button
      onClick={handleClick}
      className="
        
        flex justify-center items-center
        rounded-full
        w-14
        h-14
        bg-white
        ease-in-out
        transition-all
        hover:bg-sky-50/80
        m-2
        p-3
        "
    >
      {children}
    </button>
  );
}
