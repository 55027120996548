import { useDispatch, useSelector } from "react-redux";
import { clearGeocoderResult } from "../../reducers/map.ts";

import { Marker } from "react-map-gl";

import marker from "../../assets/map-point-icon.png";

/**
 * Displays a marker at the coordinates of the geocoder result
 */
function MarkerGeocoderResult() {
  const dispatch = useDispatch();
  const { geocoderActive: active, geocoderCoordinates: coordinates } =
    useSelector((state) => state.map);

  if (!active) return null;

  return (
    <Marker
      onClick={() => dispatch(clearGeocoderResult())}
      longitude={coordinates[0]}
      latitude={coordinates[1]}
      color="rgb(117, 131, 255)"
    >
      <img src={marker} alt="map marker" width="35" />
    </Marker>
  );
}

export default MarkerGeocoderResult;
