import { useSelector } from "react-redux";

const heatmapTilesetName = process.env.REACT_APP_MAPBOX_HEATMAP_TILESET_NAME;

/**
 * Manages the style definitions for the heatmap on the map
 */
export function useHeatmapStyleDefinition() {
  const { dewiCoverageVisible: heatmapVisible } = useSelector(
    (state) => state.map
  );

  const heatmapLayerStyle = {
    id: "phase0-locations",
    type: "heatmap",
    "source-layer": heatmapTilesetName,
    layout: {
      visibility: heatmapVisible ? "visible" : "none",
    },
    paint: {
      // The following is a different mechanism for use
      "heatmap-weight": {
        property: "count",
        type: "exponential",
        stops: [
          [1, 0],
          [62, 1],
        ],
      },

      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        "rgba(230, 255, 80, 0)",
        0.2,
        "rgba(230, 255, 80, 0.1)",
        0.4,
        "rgba(230, 255, 80, 0.2)",
        0.6,
        "rgba(230, 255, 80, 0.44)",
        0.8,
        "rgba(230, 255, 80, 0.6)",
        1,
        "rgba(230, 255, 80, 1)",
      ],
      // Adjust the heatmap radius by zoom level
      "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 6, 4, 9, 20],
      // Transition from heatmap to circle layer by zoom level
      "heatmap-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        7,
        1,
        10,
        0.7,
        13,
        0,
      ],
    },
  };

  return {
    heatmapLayerStyle,
  };
}
