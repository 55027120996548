import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

export function useInitialLoad() {
  const [initialLoad, setInitialLoad] = useState(true);
  const { map } = useMap();

  const { hexagon, status } = useSelector((state) => state.hexagon);

  const zoom = 13.76;

  useEffect(() => {
    if (!initialLoad || !map || status === "loading") return;

    const { centroid } = hexagon;
    if (centroid?.lat && centroid?.lng) {
      map.flyTo({ center: centroid, zoom });
    }

    setInitialLoad(false);

    // when the map is loaded and there is a hexagon, zoom to it and set initialLoad to false
  }, [map, status]); // eslint-disable-line react-hooks/exhaustive-deps
}
