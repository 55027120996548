import { useMap } from "react-map-gl";

/**
 * returns a function that uses the browser geolocation getlocation to get
 * the user's location and then sets the map's view to that location
 */
function useRetrieveLocation() {
  const { map } = useMap();

  return {
    retrieveLocation: () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    },
  };
}
export default useRetrieveLocation;
