import { useEffect } from "react";
import { MapProvider } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { fetchHexagon, resetHexagon } from "../../reducers/hexagon.ts";
import { GeocoderContainer } from "../GeocoderInput/GeocoderContainer";
import { SidepanelIntro } from "../SidepanelIntro/SidepanelIntro";
import { MapboxMap } from "../MapboxMap/MapboxMap";
import { SidepanelHexagonCell } from "../SidepanelHexagonCell/SidepanelHexagonCell";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useQuery } from "../../hooks/useQuery";
import { analyticsPageview } from "../../utils/googleAnalytics";

import ControlsMapLayer from "../ControlsMapLayer.jsx";
import SidepanelMobileLayerToggle from "../SidepanelMobileLayerToggle.jsx";

export function ExplorerLayout() {
  useIsMobile();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { hexagonId } = useParams();
  const { status: hexagonStatus } = useSelector((state) => state.hexagon);
  // preview mode for explorer in iframe
  const preview = useQuery("preview");

  useEffect(() => {
    analyticsPageview({ path: pathname });
  }, [pathname]);

  useEffect(() => {
    if (hexagonId) {
      dispatch(fetchHexagon(hexagonId));
    } else if (hexagonStatus === "succeeded") {
      dispatch(resetHexagon());
    }
  }, [hexagonId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`w-screen h-screen overflow-hidden lg:grid bg-black font-aeonik ${
        preview ? "lg:grid-cols-1fr" : "lg:grid-cols-[448px_1fr]"
      }`}
    >
      {!preview && (
        <div>
          <SidepanelIntro />
          <SidepanelHexagonCell />
          <SidepanelMobileLayerToggle />
        </div>
      )}
      <MapProvider>
        <MapboxMap />
        <ControlsMapLayer preview={preview} />
        <GeocoderContainer />
      </MapProvider>
    </div>
  );
}
