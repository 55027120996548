import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { GeocoderInput } from "./GeocoderInput";

import { setGeocoderResult, clearGeocoderResult } from "../../reducers/map.ts";

/** A redux-like container for the geocoder that retrieves map-center and helps position the child elements. Requires the map context from react-map-gl */
export function GeocoderContainer() {
  const { map } = useMap();
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState();

  const {
    hexagon: { id: hexagonId },
  } = useSelector((state) => state.hexagon);

  const initialPanelOpen = useSelector((state) => state.sidepanel.initialPanel);
  const { mobile } = useSelector((state) => state.mobile);

  const hideGeocoder = mobile && (!!hexagonId || initialPanelOpen);

  async function handleLocationSelect({ center, zoom }) {
    dispatch(setGeocoderResult(center));

    map.flyTo({
      center,
      zoom,
      // essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

  useEffect(() => {
    if (!map) return;

    function updateMapCenter(e) {
      setMapCenter(e.target.getCenter().toArray());
    }

    map.on("load", updateMapCenter);
    map.on("moveend", updateMapCenter);

    return () => {
      map.off("moveend", updateMapCenter);
    };
  }, [map]);

  return (
    <>
      <aside
        className={`fixed z-10 top-5 lg:top-10 lg:right-10 w-full lg:w-40 transition-all duration-200 ${
          hideGeocoder ? "opacity-0" : "opacity-1000"
        }`}
      >
        <GeocoderInput
          mapCenter={mapCenter}
          clearPin={() => dispatch(clearGeocoderResult())}
          handleLocationSelect={handleLocationSelect}
        />
      </aside>
      <span className="hidden opacity-0" />
      <span className="hidden opacity-100" />
    </>
  );
}
