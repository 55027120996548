import { useSelector } from "react-redux";

/**
 * Manages the style definitions for the labels on the map
 */
export function useLabelStyleDefinitions() {
  const { dewiCoverageVisible: labelsVisible } = useSelector(
    (state) => state.map
  );
  const reallyYellow = "#E6FF50";
  const reallyGreen = "#10E566";

  const yellowButGreenIfFullyCovered = [
    "case",
    ["boolean", [">=", ["get", "radioCount"], 6], true],
    reallyGreen,
    reallyYellow,
  ];

  const hideOnActiveOpacity = (visibleOpacity) => [
    "case",
    ["boolean", ["feature-state", "active"], false],
    0,
    visibleOpacity,
  ];

  const showOnActiveOpacity = (visibleOpacity) => [
    "case",
    ["boolean", ["feature-state", "active"], false],
    visibleOpacity,
    0,
  ];

  // The large refers to the title, not the subtitle
  function generateFontSizes() {
    const factor = 1;
    // prettier-ignore
    return [
        //  zoom, font size
            0, 4 * factor, 
            12, 8 * factor, 
            14, 26 * factor, 
            15, 50 * factor,
            16, 60 * factor
        ]
  }

  const hexagonLabelTitleActive = {
    type: "symbol",
    layout: {
      visibility: labelsVisible ? "visible" : "none",
      "text-field": ["get", "numAvailableRadios"],
      "text-anchor": "center",
      // prettier-ignore
      // 'text-padding': 2,
      'text-font': [
      'Aeonik Bold',
      'Open Sans Regular',
      'Arial Unicode MS Regular',
    ],
      // prettier-ignore
      'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom level, font size
                ...generateFontSizes(true)
            ],

      "text-justify": "center",
    },
    paint: {
      "text-halo-color": ["get", "activeLabelHaloColor"],
      "text-halo-width": 20000,
      "text-halo-blur": 20,

      "text-color": ["get", "activeLabelColor"],

      "text-opacity": showOnActiveOpacity(1),
    },
  };

  const hexagonLabelTitle = {
    type: "symbol",
    layout: {
      visibility: labelsVisible ? "visible" : "none",
      "text-field": ["get", "numAvailableRadios"],
      "text-anchor": "center",
      // prettier-ignore
      // 'text-offset': generateLabelOffsets(true),

      'text-font': [
            'Aeonik Bold',
            'Open Sans Regular',
            'Arial Unicode MS Regular',
        ],
      // prettier-ignore
      'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom level, font size
                ...generateFontSizes(true)
            ],
      "text-justify": "center",
    },
    paint: {
      "text-halo-color": yellowButGreenIfFullyCovered,
      "text-halo-width": 20000,
      "text-halo-blur": 20,

      "text-color": "black",
      "text-opacity": hideOnActiveOpacity(1),
    },
  };

  return {
    hexagonLabelTitle,
    hexagonLabelTitleActive,
  };
}
