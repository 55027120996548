import { ExplorerLayout } from "./components/ExplorerLayout/ExplorerLayout";

import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import { store } from "./store.ts";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<ExplorerLayout />}>
          <Route path="hexagon/:hexagonId" element={<ExplorerLayout />} />
        </Route>
        <Route path="*" element={<ExplorerLayout />} />
      </>
    )
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
