import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchGeocodingResults } from "../api/fetchGeocodingResults.ts";

const initialState = {
  keyContent: "",
  activeResult: 0,
  results: [],
  status: "idle",
  error: "",
};

export const hexagonSlice = createSlice({
  name: "hexagon",
  initialState: initialState as any,
  extraReducers: (builder) => {
    builder.addCase(fetchGeocoderContent.pending, (state, action) => {
      state.status = "loading";
      state.keyContent = action.meta.arg.inputValue;
      state.results = [];
      state.activeResult = 0;
      state.error = "";
    });
    builder.addCase(fetchGeocoderContent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.results = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGeocoderContent.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.results = [];
    });
  },
  reducers: {
    setActiveResult: (state, action) => {
      state.activeResult = action.payload;
    },
  },
});

export const { setActiveResult } = hexagonSlice.actions;

type GeocoderFetchArgs = {
  inputValue: string;
  mapCenter: [number, number] | string;
};

export const fetchGeocoderContent = createAsyncThunk(
  "geocoder/fetchResult",
  async ({ inputValue, mapCenter }: GeocoderFetchArgs) => {
    if (!inputValue.length) {
      return [];
    }

    const access_token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const limit = "5";

    const searchArgs = {
      access_token: access_token,
      limit: limit,
      proximity: String(mapCenter),
    };

    const searchParams = new URLSearchParams(searchArgs)

      .toString()
      .replace(/%0A/g, " ")
      .replace(/\n/g, " ");
    const response = await fetchGeocodingResults(inputValue, searchParams);

    return response;
  }
);

export const { internalClearHexagonId } = hexagonSlice.actions;

const { reducer } = hexagonSlice;
export default reducer;
