/**
 * @fileoverview This file contains the style definitions for the hexagons on the map.
 * It is used in the HexagonPolygonLayer.jsx file and is static.
 */

const zoomOfTransition = 12;

const reallyYellow = "#E6FF50";
const reallyGreen = "#10E566";

const spotsAvailableOpacity = {
  0: 0.9,
  1: 0.9,
  2: 0.7,
  3: 0.54,
  4: 0.36,
  5: 0.18,
  6: 0,
};

const hideOnActiveOpacity = (visibleOpacity) => [
  "case",
  ["boolean", ["feature-state", "active"], false],
  0,
  visibleOpacity,
];

const showOnActiveOpacity = (visibleOpacity) => [
  "case",
  ["boolean", ["feature-state", "active"], false],
  visibleOpacity,
  0,
];

// Assumes that when the radioCount is greater than 6, that the hexagon is fully covered
const yellowButGreenIfFullyCovered = [
  "case",
  ["boolean", [">=", ["get", "radioCount"], 6], true],
  reallyGreen,
  reallyYellow,
];

const stepColors = [
  "case",
  ["==", ["get", "radioCount"], 0],
  reallyGreen,
  reallyYellow,
];
const stepOpacity = [
  "case",
  ["==", ["get", "radioCount"], 0],
  spotsAvailableOpacity[6],
  ["==", ["get", "radioCount"], 1],
  spotsAvailableOpacity[5],
  ["==", ["get", "radioCount"], 2],
  spotsAvailableOpacity[4],
  ["==", ["get", "radioCount"], 3],
  spotsAvailableOpacity[3],
  ["==", ["get", "radioCount"], 4],
  spotsAvailableOpacity[2],
  ["==", ["get", "radioCount"], 5],
  spotsAvailableOpacity[1],
  spotsAvailableOpacity[0],
];

const transitionHexagonFillOpacity = [
  "interpolate",
  ["linear"],
  ["zoom"],

  9.5,
  0,
  10,
  0.2,
  10.5,
  0.4,
  zoomOfTransition,
  0.75,
];

export const hexagonTransitionFillLayer = {
  type: "fill",
  paint: {
    "fill-antialias": false,
    "fill-color": stepColors,
    "fill-opacity": transitionHexagonFillOpacity,
  },
};

// property in question in 'radioCount'

export const hexagonTransitionStrokeLayer = {
  type: "line",
  paint: {
    "line-color": yellowButGreenIfFullyCovered,
    "line-width": 2,
    "line-opacity": transitionHexagonFillOpacity,
  },
};

export const polygonFillLayer = {
  type: "fill",
  paint: {
    "fill-antialias": false,
    "fill-color": yellowButGreenIfFullyCovered,
    "fill-opacity": hideOnActiveOpacity(stepOpacity),
  },
};

export const polygonActiveFillLayer = {
  type: "fill",
  paint: {
    "fill-antialias": false,
    "fill-color": yellowButGreenIfFullyCovered,
    "fill-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],

      zoomOfTransition - 2,
      0,
      zoomOfTransition,
      showOnActiveOpacity(stepOpacity),
    ],
  },
};

export const polygonStrokeLayer = {
  type: "line",
  paint: {
    "line-color": yellowButGreenIfFullyCovered,
    "line-width": 2,
    "line-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],

      zoomOfTransition - 2,
      0,
      zoomOfTransition,
      hideOnActiveOpacity(1),
    ],
  },
};

export const polygonActiveStrokeLayer = {
  type: "line",
  paint: {
    "line-color": "white",
    "line-width": ["interpolate", ["linear"], ["zoom"], 10, 0, 14, 12],
    "line-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],

      zoomOfTransition - 2,
      0,
      zoomOfTransition,
      showOnActiveOpacity(1),
    ],
  },
};

export const heatmapLayer = ({ sourceLayer }) => ({
  id: "phase0-locations",
  type: "heatmap",
  "source-layer": sourceLayer,
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    // 'heatmap-weight': [
    //     'interpolate',
    //     ['linear'],
    //     ['get', 'count'],
    //     0,
    //     0,
    //     6,
    //     1
    // ],

    // The following is a different mechanism for use
    "heatmap-weight": {
      property: "count",
      type: "exponential",
      stops: [
        [1, 0],
        [62, 1],
      ],
    },

    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(230, 255, 80, 0)",
      0.2,
      "rgba(230, 255, 80, 0.1)",
      0.4,
      "rgba(230, 255, 80, 0.2)",
      0.6,
      "rgba(230, 255, 80, 0.44)",
      0.8,
      "rgba(230, 255, 80, 0.6)",
      1,
      "rgba(230, 255, 80, 1)",
    ],
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 6, 4, 9, 20],
    // Transition from heatmap to circle layer by zoom level
    "heatmap-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      7,
      1,
      9.5,
      0.5,
      10.5,
      0,
    ],
  },
});

// The large refers to the title, not the subtitle
const generateFontSizes = () => {
  const factor = 1;
  // prettier-ignore
  return [
        //  zoom, font size
            0, 4 * factor, 
            12, 8 * factor, 
            14, 26 * factor, 
            15, 50 * factor,
            16, 60 * factor
        ]
};

export const hexagonLabelTitleActive = {
  type: "symbol",
  layout: {
    "text-field": ["get", "numAvailableRadios"],
    "text-anchor": "center",
    // prettier-ignore
    // 'text-padding': 2,
    'text-font': [
      'Aeonik Bold',
      'Open Sans Regular',
      'Arial Unicode MS Regular',
    ],
    // prettier-ignore
    'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom level, font size
                ...generateFontSizes(true)
            ],

    "text-justify": "center",
  },
  paint: {
    "text-halo-color": ["get", "activeLabelHaloColor"],
    "text-halo-width": 20000,
    "text-halo-blur": 20,

    "text-color": ["get", "activeLabelColor"],

    "text-opacity": showOnActiveOpacity(1),
  },
};

export const hexagonLabelTitle = {
  type: "symbol",
  layout: {
    "text-field": ["get", "numAvailableRadios"],
    "text-anchor": "center",
    // prettier-ignore
    // 'text-offset': generateLabelOffsets(true),

    'text-font': [
            'Aeonik Bold',
            'Open Sans Regular',
            'Arial Unicode MS Regular',
        ],
    // prettier-ignore
    'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom level, font size
                ...generateFontSizes(true)
            ],
    "text-justify": "center",
  },
  paint: {
    "text-halo-color": yellowButGreenIfFullyCovered,
    "text-halo-width": 20000,
    "text-halo-blur": 20,

    "text-color": "black",
    "text-opacity": hideOnActiveOpacity(1),
  },
};
