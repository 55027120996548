import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHexagonContent } from "../api/fetchHexagonContent.ts";
import type { Hexagon } from "../api/fetchHexagonContent.ts";

const initialState = {
  hexagon: {
    id: "",
    nodes: [],
    radioSpacesAvailable: 0,
    radioSpacesPossible: 6,
    city: "",
    error: "",
    centroid: {},
  },
  status: "idle",
  error: "",
};

type HexagonState = {
  hexagon: Hexagon;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
  closing: boolean;
};

export const hexagonSlice = createSlice({
  name: "hexagon",
  initialState: initialState as HexagonState,
  extraReducers: (builder) => {
    builder.addCase(fetchHexagon.pending, (state, action) => {
      state.status = "loading";
      state.hexagon = { ...state.hexagon, id: action.meta.arg };
      state.error = "";
    });
    builder.addCase(fetchHexagon.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.hexagon = action.payload;
      state.error = "";
    });
    builder.addCase(fetchHexagon.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
  reducers: {
    internalClearHexagonId: (state) => {
      state.hexagon = { ...state.hexagon, id: "" };
    },
    internalClearActiveHexagon: (state) => {
      state.hexagon = initialState.hexagon;
      state.status = "idle";
      state.error = "";
    },
  },
});

const { internalClearActiveHexagon } = hexagonSlice.actions;

export const fetchHexagon = createAsyncThunk(
  "hexagon/fetchById",
  async (hexagonId: string) => {
    if (!hexagonId) {
      console.error("Attempted to fetch hexagon with no id");
      return initialState.hexagon;
    }

    const response = await fetchHexagonContent(hexagonId);

    return { ...response, id: hexagonId };
  }
);

export const resetHexagon = createAsyncThunk(
  "hexagon/reset",
  async (_, { dispatch }) => {
    // dispatch(internalClearHexagonId())
    // await sleep(500)

    dispatch(internalClearActiveHexagon());
  }
);

const { reducer } = hexagonSlice;
export default reducer;
