import React from "react";

function HexagonSmall() {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.94215e-07 13.25L7.33854e-07 4.75L7.5 0.5L15 4.75L15 13.25L7.5 17.5L2.94215e-07 13.25Z"
        fill="#7151F0"
      />
    </svg>
  );
}

export default HexagonSmall;
