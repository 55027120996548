import { useSelector, useDispatch } from "react-redux";

import { closeMobileLayerSelectPanel } from "../reducers/sidepanel.ts";
import { toggleDewiCoverage, toggleEnhancedCoverage } from "../reducers/map.ts";

function SidepanelMobileLayerToggle() {
  const dispatch = useDispatch();
  const { dewiCoverageVisible, enhancedCoverageVisible } = useSelector(
    (state) => state.map
  );

  const { mobileLayerSelector } = useSelector((state) => state.sidepanel);

  const toggleDewi = () => dispatch(toggleDewiCoverage());
  const toggleEnhanced = () => dispatch(toggleEnhancedCoverage());

  const handleClose = () => {
    dispatch(closeMobileLayerSelectPanel());
  };

  const drawerState = mobileLayerSelector
    ? " drawer-bottom-mobile-open "
    : " drawer-bottom-mobile-closed ";

  return (
    <>
      <aside
        className={
          `w-full pt-4 bg-gray-900 bg-opacity-5 inset-0 transform ease-in-out z-20 mt-[23rem] fixed` +
          drawerState
        }
      >
        <section
          className={
            "w-full left-0 h-full absolute bg-black shadow-xl delay-400 duration-500 ease-in-out transition-all transform" +
            (mobileLayerSelector
              ? " translate-y-0 translate-x-0 "
              : " translate-y-full lg:translate-y-0 lg:-translate-x-full ")
          }
        >
          <div className="flex justify-end w-full">
            <button className="p-2" onClick={handleClose}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 16L16 48M16 16L48 48"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="px-4 overflow-y-auto hide-scrollbar">
            <h3 className="text-4xl mb-3 text-white font-light pl-4">Layers</h3>
            <button
              className="text-white mx-3 p-2 rounded-sm"
              onClick={toggleEnhanced}
            >
              <input
                checked={enhancedCoverageVisible}
                readOnly
                type="checkbox"
                name=""
                id=""
                className="checkbox-white mb-[14px] mr-3 accent-white w-6 h-6 rounded relative top-[6px]"
              />
              <span>REALLY Coverage</span>
            </button>
            <button
              className="text-white mx-3 p-2 rounded-sm"
              onClick={toggleDewi}
            >
              <input
                checked={dewiCoverageVisible}
                readOnly
                type="checkbox"
                name=""
                id=""
                className="checkbox-white mb-[14px] mr-3 accent-white w-6 h-6 rounded relative top-[6px]"
              />
              <span>REALLY Enhanced Coverage</span>
            </button>
          </div>
        </section>
      </aside>
      <span className="hidden z-10 mt-40 drawer-left-mobile-open" />
      <span className="hidden z-20 mt-0 drawer-left-mobile-closed" />
      <span className="hidden z-30 drawer-bottom-mobile-open" />
      <span className="hidden z-40 drawer-bottom-mobile-closed" />
      <span className="hidden z-50 pl-12" />
    </>
  );
}

export default SidepanelMobileLayerToggle;
