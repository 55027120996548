import { useMap } from "react-map-gl";
import { useEffect, useState } from "react";

import { deserialize } from "flatgeobuf/lib/mjs/geojson.js";
import throttle from "lodash.throttle";

const blankGeojson = () => ({
  type: "FeatureCollection",
  features: [],
});

/**
 * Loads a flatgeobuf file and updates the map with the data as needed
 */
export function useLoadFlatgeobuf({
  url,
  minzoom,
  activeCellId = null,
  centroids = false,
  setFeatureState = () => {},
}) {
  const { map } = useMap();
  const [data, setData] = useState(null);

  function resetData() {
    setData(blankGeojson());
  }

  useEffect(() => {
    async function loadData(e) {
      if (minzoom && map.getZoom() < minzoom) {
        setData(blankGeojson());
        return;
      }

      const [[minX, minY], [maxX, maxY]] = e.target.getBounds().toArray();
      const fc = { type: "FeatureCollection", features: [] };
      let iter = deserialize(url, {
        minX,
        maxX,
        minY,
        maxY,
      });

      let polygon = false;

      for await (let feature of iter) {
        if (feature.geometry.type === "Polygon") polygon = true;

        const { radioCount } = feature.properties;
        feature.properties.transitionVisible = String(radioCount > 0);

        if (centroids) {
          if (radioCount >= 6) {
            feature.properties.numAvailableRadios = "FULLY \n COVERED";
            feature.properties.activeLabelColor = "black";
          } else if (radioCount === 5) {
            feature.properties.numAvailableRadios = "1 SPOT \n AVAILABLE";
            feature.properties.activeLabelColor = "white";
          } else if (radioCount >= 0) {
            feature.properties.numAvailableRadios = `${
              6 - radioCount
            } SPOTS \n AVAILABLE`;
            feature.properties.activeLabelColor = "white";
          }
        } else {
          if (radioCount === 0) {
            feature.properties.backgroundVisibility = 0.1;
          } else if (radioCount === 1) {
            feature.properties.backgroundVisibility = 0.3;
          } else {
            // prettier-ignore
            feature.properties.backgroundVisibility = (radioCount) / 10 + 0.3
          }
        }
        fc.features.push({
          ...feature,
          id: parseInt(feature.properties.hexid, 16) ?? -1,
        });
      }

      if (polygon) window.currentFeatures = fc;
      setData(fc);

      if (activeCellId) {
        setFeatureState(activeCellId);
      }
    }

    const throttledLoadData = throttle(loadData, 1000, { trailing: true });

    map.once("load", loadData);
    map.on("move", throttledLoadData);

    return () => {
      resetData();
      map.off("move", throttledLoadData);
    };
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data };
}
