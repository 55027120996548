import * as Sentry from "@sentry/react";

/**
 * Fetches the content for a single hexagon
 *
 * @param {string} hexId string id of a hexagon
 */

export type Hexagon = {
  // The content of the node is no longer important, and should be replaced by a count in a future effort
  nodes: any[];
  centroid?: { lat: number; lng: number };
  radioSpacesAvailable: number;
  radioSpacesPossible: number;
  city: string;
  error?: string;
};

export async function fetchHexagonContent(hexId: string): Promise<Hexagon> {
  const response = await fetch(
    `${process.env.REACT_APP_SERVICES_API_ENDPOINT}/explore/hexagon/${hexId}`
  );

  if (response.status === 404) {
    Sentry.captureException(`hexagon response status 404: ${hexId}`);
    throw new Error("response status 404");
  } else if (response.status >= 500) {
    Sentry.captureException(
      `hexagon response status 5xx: ${hexId}, ${response.status}`
    );
    throw new Error("response status 5xx");
  } else if (response.status !== 200) {
    console.error("Failure to fetch data for a given hexagon", response);
    Sentry.captureException(
      `hexagon response status not 200: ${hexId}, ${response.status}`
    );
    throw new Error("client error");
  }
  const data = await response.json();

  const { nodes, radioSpacesAvailable, radioSpacesPossible, city, centroid } =
    data;

  return {
    nodes,
    radioSpacesAvailable,
    radioSpacesPossible,
    city,
    centroid,
    error: "",
  };
}
