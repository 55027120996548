import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MobileState {
  mobile: boolean;
}

const initialState: MobileState = {
  mobile: true,
};

export const mobileSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setMobileStatus: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMobileStatus } = mobileSlice.actions;

export default mobileSlice.reducer;
