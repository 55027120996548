import React from "react";
import { useDispatch } from "react-redux";

import { ButtonMapInterface } from "./ButtonMapInterface.tsx";
import { openMobileLayerSelectPanel } from "../reducers/sidepanel.ts";

function ControlsMapLayerMobile() {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openMobileLayerSelectPanel());

  return (
    <ButtonMapInterface handleClick={handleClick}>
      <svg
        width="26"
        height="18"
        viewBox="0 0 26 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9H25M1 1H25M1 17H25"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ButtonMapInterface>
  );
}

export default ControlsMapLayerMobile;
