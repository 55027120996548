import React from "react";
import PropTypes from "prop-types";
import ChevronDown from "../../icons/ChevronDown";
import BackIcon from "../../icons/Back";
import ReallyWordmark from "../../icons/ReallyWordmark";

export function SidepanelLogoElement(props) {
  return (
    <>
      <div className="mt-2 flex justify-between w-full">
        <div className="w-2/3 relative md:right-3">
          <a href="//really.com" target="_blank" rel="noopener noreferrer">
            <ReallyWordmark />
          </a>
        </div>
        <button
          className="lg:hidden"
          onClick={props.handleClose("SidepanelLogoElement 1")}
        >
          <ChevronDown />
        </button>
      </div>
      {props.hideLeftArrow ? (
        <></>
      ) : (
        <button
          className="items-center mt-4 text-white ease-in-out transition-all hover:border-b-yellow-really border-b-transparent border-b hidden lg:flex h-8"
          onClick={props.handleClose("SidepanelLogoElement 2")}
        >
          <BackIcon />
          <span className="ml-3">Back</span>
        </button>
      )}
    </>
  );
}

SidepanelLogoElement.propTypes = {
  /** Triggers sidepanel close. Used for mobile down chevron */
  handleClose: PropTypes.func.isRequired,
  /** If set to true, hides the back arrow on desktop */
  hideLeftArrow: PropTypes.bool,
};

SidepanelLogoElement.defaultProps = {
  hideLeftArrow: false,
};
