import { useMemo } from "react";
import { Source, Layer, useMap } from "react-map-gl";
import PropTypes from "prop-types";
import { useLoadFlatgeobuf } from "./useLoadGeobuf";
import { useLabelStyleDefinitions } from "./useLabelStyleDefinitions";

const minZoomLabels = 12;

/**
 * Using a point flatgeobuf file, renders labels for each hexagon
 */
function HexagonLabelsLayer({ fileLocation }) {
  const { data } = useLoadFlatgeobuf({
    url: fileLocation,
    centroids: true,
    minzoom: minZoomLabels,
  });
  const { current } = useMap();

  const { hexagonLabelTitle, hexagonLabelTitleActive } =
    useLabelStyleDefinitions();

  const transitionFilter = useMemo(
    () => ["==", ["get", "transitionVisible"], "true"],
    []
  );

  const filterProps = {};
  if (current.getLayer("hexagon-title")) filterProps.beforeId = "hexagon-title";

  // Force unmount of labelling data when there is no data present
  return (
    <Source type="geojson" data={data} id="hexagon-centroids">
      <Layer
        minzoom={minZoomLabels}
        maxzoom={13.5}
        {...hexagonLabelTitle}
        id="hexagon-title-filtered"
        {...filterProps}
        filter={transitionFilter}
      />
      <Layer
        minzoom={minZoomLabels}
        maxzoom={13.5}
        {...hexagonLabelTitleActive}
        id="hexagon-title-filtered-active"
        {...filterProps}
        filter={transitionFilter}
      />
      <Layer minzoom={13.5} {...hexagonLabelTitle} id="hexagon-title" />
      <Layer
        minzoom={13.5}
        {...hexagonLabelTitleActive}
        id="hexagon-title-active"
      />
    </Source>
  );
}

HexagonLabelsLayer.propTypes = {
  /** The public URL that contains the flatgeobuf in question */
  fileLocation: PropTypes.string.isRequired,
};
/** Uses a static flatgeobuf file to render the titles and subtitles of each hexagon */
export default HexagonLabelsLayer;
