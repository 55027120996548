import { configureStore } from "@reduxjs/toolkit";
import hexagonReducer from "./reducers/hexagon.ts";
import mobileStatusReducer from "./reducers/mobile.ts";
import mapReducer from "./reducers/map.ts";
import sidepanelReducer from "./reducers/sidepanel.ts";
import geocoderReducer from "./reducers/geocoder.ts";

export const store = configureStore({
  reducer: {
    hexagon: hexagonReducer,
    mobile: mobileStatusReducer,
    map: mapReducer,
    sidepanel: sidepanelReducer,
    geocoder: geocoderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
