import PropTypes from "prop-types";
import LocationTrigger from "../icons/LocationTrigger";
import { ButtonMapInterface } from "./ButtonMapInterface.tsx";

/** A small button that has a location icon embedded */
function ButtonGetLocation({ handleClick }) {
  return (
    <ButtonMapInterface handleClick={handleClick}>
      <LocationTrigger />
    </ButtonMapInterface>
  );
}

ButtonGetLocation.propTypes = {
  /** A function to handle the click event */
  handleClick: PropTypes.func.isRequired,
};

export default ButtonGetLocation;
